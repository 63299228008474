.containerFluid{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 2rem;
}

.header{
  color: var(--dark);
  font-weight: 500;
  font-size:9rem;
}
.message{
  color:var(--dark);
  display:block;
}


@media screen and (min-width: 1000px) {
  .message{
    color:var(--dark);
    display:block;
  }
  .message:nth-of-type(2){
    font-size: 1.4rem;
    margin-top: 1.3rem;
  }
  .containerFluid{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  .header{
    color: var(--dark);
    font-weight: 500;
    font-size:9rem;

  }

}
