.container{
  margin-top: 3rem;
}
.formControl{
  outline: 0;
  padding: 1.1rem 1.8rem;
  border:1px solid var(--color-border);
  color: var(--dark);
  border-radius: 30px;
  font-size: 2rem;
  font-family:  'Inter', sans-serif;

}
.inputGroup .formControl{
  border:0;
  flex-grow: 1;
  padding: 1.1rem 1.2rem;
}
.inputGroup{
  border: 1px solid var(--color-border);
  border-radius: 10px;
  display: flex;
  transition: all 500ms ease-in;
}
.inputGroup:hover{
  border: 1px solid var(--blue);
  box-shadow: 0 0 0 2px rgba(0, 98, 255, 0.252);
}
::placeholder{
  color: var(--gray);
}
.icon {
  color: var(--color-border);
  width: 50px;
}

.inputGroup .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width:1000px) {
  .formControl{
    font-size: 1.5rem;
  }
  
}
