:root {
  --orange: #f7936f;
  --hover-color: #e1331f;
  --blue: #4c6fff;
  --bColor: #7e7e7e;
  --white: #ffffff;
  --black: #000000;
  --heading-dark: #27272e;
  --text-body-dark: #425466;
  --theme-primary-light: #7895ff;
  --dashboard-bg: #f7fafc;
  --success: #66cb9f;
  --body-muted: #718096;
  --error: #f16063;
  --soft-blue: #e1e8ff;
  --black: #000000;

  --white: #ffffff;
  --white-1: #e7e7ed;

  --light: #9a9ea6;
  --light-1: #425466;
  --light-2: #7a828a;

  --gray-10: #f7fafc;
  --gray-20: #edf2f7;
  --gray-30: #e2e8f0;

  --dark: #16192c;
  --dark-1: #2f234f;
  --dark-2: #27272e;
  --dark-3: #222;
  --dark-opacity-2: rgba(22, 25, 44, 0.2);
  --dark-opacity-25: rgba(22, 25, 44, 0.25);

  --primary: #4475f2;
  --primary-1: #3754db;
  --primary-2: #e1e8ff;
  --primary-3: #4c6fff;
  --primary-4: #2e5aac;
  --primary-5: #89a7e0;

  --secondary: #e3ebfd;
  --secondary-1: #e4ecf7;

  --muted: #718096;

  --success: #66cb9f;
  --success-1: #deffee;

  --fail: #cf4655;
  --fail-1: #ffeef1;

  --info-1: #68dbf2;

  --warning-1: #ffede3;
  --warning-2: #f7936f;

  --tertiary: #ff92ae;

  --disabled: #b1b4ba;

  --table-head: #8492a6;
  --table-text: #272833;

  /* gradients */
  --primary-gradient-1: #d665ff, #4c6fff;
  /* border-radius */
  --border-rad-xss: 0.7rem;
  --border-rad-xs: 0.8rem;
  --border-rad-md: 2.2rem;
  --border-rad-lg: 3rem;

  /* shadows */
  --shadow-sm: 0rem 0rem 0.1rem rgba(12, 26, 75, 0.24),
    0rem 0.3rem 0.8rem -0.1rem rgba(50, 50, 71, 0.05);
  --shadow-part-1: 0rem 0rem 0.1rem rgba(12, 26, 75, 0.24);
  --shadow-part-2: 0rem 0.4rem 2rem -0.2rem rgba(50, 50, 71, 0.08);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  font-size: 2.5rem;
  min-height: 100vh;
  width: 100%;
  font-family: "Inter", sans-serif;
  background-color: var(--bg-color);
  /* overflow-x: hidden; */
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

:root {
  --bg-color: #f2f2f2;
  --color-border: rgb(169, 169, 169);
  /* --blue: #4c6fff; */
  --blue: rgb(54, 85, 240);
  --light-blue: #195ec0;
  --white: #fff;
  --form-border-color: rgb(148, 148, 148);
  --gray: rgb(89, 89, 89);
  --dark: #27272e;
  --red: #f00;
  --green: #4cbb17;
  --light-green: #49968a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* table  */
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #a6b7d4;
  border-radius: 30px;
}
.exam-images__buttons {
  flex-wrap: wrap;
  margin-top: 1.2rem;
  display: flex;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background: #e4ecf7;
}

/* popover */
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
}

/* pagination */
.pagination__item-dot {
  border: none !important;
  padding: 0 !important;
}

/* alerts */
.alert__cont {
  align-items: flex-start !important;
  max-width: 47.5rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  border-radius: 1.6rem !important;
}

.alert__cont div:nth-child(2) {
  justify-content: flex-start;
  display: flex;
  align-self: center;
  align-items: center;
}

.alert__icon-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 50%;
  padding: 0.6rem;
  /* color: #fff !important; */
}

.alert__icon-cont-success {
  background: #4aae8c;
}

.alert__icon-cont-error {
  background-color: rgb(192, 86, 86);
}

.alert__success {
  background: #66cb9f !important;
  color: #ffffff !important;
}

.alert__error {
  background: rgb(227, 107, 107) !important;
  color: #ffffff !important;
}

div.go2072408551 {
  padding: 16px;
}

div.go3958317564 {
  font-size: 14px;
  line-height: 200%;
  margin: 0 16px;
}

a {
  text-decoration: none;
}
