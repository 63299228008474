.container {
  width: 100%;
  height: 100%;
  margin-top: 2.5rem;
}

.cardBody {
  padding: 2rem 3rem;
}

.cardTitle{
    margin-bottom: 2.5rem;
}

@media screen and (min-width: 1000px) {
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height:100%;
    background-color: var(--white);

  }

  .card {
    box-shadow: 0 2px 8px rgb(177, 176, 176);
    border-radius: 5px;
    margin: 5rem 0;

  }

  .cardBody {
    padding: 3rem 3rem;
  }
}
