@keyframes circle {
  to {
    transform: rotate(360deg);
  }
}
.spinner {
 display: flex;
 align-items: center;
 height: 100%;
 justify-content: center;
}
.spinner span {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 4px solid rgb(235, 234, 234);
  border-top: 4px solid rgb(42, 76, 214);
  animation-name: circle;
  animation-duration: 1500ms;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

