.formContent h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media screen and (min-width: 1000px) {
  
  .content {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .content {
    width: 39%;
    background-color: var(--white);
    height: auto;
    padding: 2rem 2rem 3rem 2rem;
    border-radius: 3px;
    box-shadow: 0 0 2px rgb(169, 169, 169);
  }
  .formContent {
    width: 100%;
  }
  .formHeader {
    font-size: 2.6rem;
    text-align: center;
    color: var(--gray);
    font-weight: 400;
  }
}

.toast {
  background-color: var(--red);
  font-size: 1.5rem;
  width: 200px;
  color: var(--white);
  height: 60px;
  font-weight: 500;
}
