.modalContent{
  width: 450px;
  background-color: #faf8f8;
  text-align: left;
}

.modal .overlay{
  width: 100vw;
  height: 100vh;
  top:0;
  bottom: 0;
  left:0;
  right: 0;
  position: fixed;
}

.overlay{
  background-color: rgba(88, 88, 88, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContent{
  padding: 1rem 2rem;
}
.modalHeader{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgb(151, 151, 151);
}
.header{
  font-size: 2rem;
  padding-top: 1.3rem;
  color: var(--red);
  font-weight: 500;
}
.message{
  margin-top: 1rem;
  color: var(--dark);
  font-size: 1.5rem;
  font-weight: 400;
}
.deleteBtn{
  margin-top: 1.5rem;
  padding: 0.8rem 1.6rem;
  border: 1px solid var(--red);
  border-radius: 3px;
  color: var(--white);
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: capitalize;
  background: transparent;
  color: var(--red);
  transition: all 500ms ease;
}
.deleteBtn:hover{
  border: 1px solid var(--red);
  background-color: var(--red);
  color: var(--white);
  font-weight: 600;
}
.btnClose{
  padding: 0.2rem 1.6rem;
  background: transparent;
}
.btnClose .icon {
  font-size: 2.5rem;
  color: var(--dark);
  background: transparent;
}
body.activeModal{
  overflow-y: hidden;
}

