.paginationItems{
  list-style-type: none;
  padding:0;
  margin:0;
  display: grid;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(30px,1fr));
  width: 250px;
 overflow: hidden;
}

.active{
  background-color: var(--blue);
  width: 100%;
  height: 100%;
  color: var(--white)
}
.paginationItem{
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-top-style: 0;
  border-bottom-style: 0;
  border-left-style: 0;
  border-right-style:solid;
  border-color: var(--gray);
  height: 28px;
  overflow: hidden;
}
.paginationItem:last-child{
  border-left-style: 0;
  border-width: 0;
}
.paginationItem .link{
  height: 100%;
  outline: 0;
  border: 0;
  width: 100%;
  color: var(--dark);
  background: transparent;
}

.paginationItem .link:hover{
  height: 100%;
  background-color: var(--blue);
    width: 100%;
  color: var(--white)
}
.paginationItems .paginationItem{
  /* border: none; */
}