.bounce {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  border-radius: 5px;
  padding: 0 2rem;
  
}

.icon {
  height: 1rem;
  width: 1rem;
  background-color: var(--bg-color);
  margin-right: 0.7rem;
  animation-name: bounce-loading;
  animation-timing-function: ease;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  transition: transform 1500ms ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.icon1 {
  animation-delay: 200ms;
  background-color: rgba(244, 244, 244, 0.5);
}
.icon2 {
  animation-delay: 300ms;
  background-color: rgba(208, 208, 208, 0.868);

}
.icon3 {
  animation-delay: 400ms;
  background-color: rgba(225, 225, 225, 0.894);

}
@keyframes bounce-loading {
  50% {
    transform: translateY(-3px);
    opacity: 0;
  }
}
