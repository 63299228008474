.formLabel {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 1.1rem;
  color: var(--dark);
}
.inputForm,
.inputFormInvalid {
  border-radius: 5px;
  height: 5rem;
  border-color: var(--gray);
  border-style: solid;
  border-width: 0.1rem;
  transition: all 500ms ease;
  display: flex;
  align-items: center;
  transition: all 500ms ease;
  overflow: hidden;
}
.inputForm:hover {
  border-color: rgb(67, 141, 252);
  box-shadow: 0 0 0 4px rgba(0, 98, 255, 0.252);
}

.inputFormInvalid {
  border-color: var(--red);
  box-shadow: 0 0 0 4px rgba(248, 20, 4, 0.253);
  color: var(--red);
}

.formControl {
  height: 100%;
  outline: 0;
  flex-grow: 6;
  flex-basis: auto;
  padding: 0 1.3rem;
  border: none;
  font-family: "Inter";
  font-weight: 500;
  background: transparent;
  color: var(--dark);
}

.formGroup {
  margin: 1.7rem 0 1.5rem 0;
}

.iconsCont {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 15px;
}
.icon {
  font-size: 2rem;
  color: var(--dark);
  transition: all 500ms ease;
}
